import Pagination from '@/component/base-pagination/index.vue'
import {awaitWrap} from "@/page/distributor/train/front/common/unit";
import RefundChangeLabel from '@/page/distributor/air-ticket/admin/component/refund-change-label/index.vue'
import buyer_dom_manage_orderCancelList from '@/lib/data-service/flight/buyer_dom_manage_orderCancelList'
import plat_dom_getAllProductType from '@/lib/data-service/flight/plat_dom_getAllProductType'

export default {
  data() {
    return {
      loading: false,
      show: false,
      params: {
        appTimeBegin: '',//申请日期开始
        appTimeEnd: '',//申请日期结束
        orderNo: '',//订单号
        pnr: '',//pnr
        ticketNumber: '',//票号
        psgName: '',//乘客姓名
        canStatus: '0',//订单状态 0:全部 1：待处理 2：取位成功 3：取位失败
        total: 0,//总数
        pageSize: 10,//每页记录条数
        currentPage: 1,//当前页码
      },
      appTimeDate: [],//申请日期
      drawerChannelList: [],//出票渠道列表
      tabList: [
        {label: '全部', name: '0'},
        {label: '待处理', name: '1'},
        {label: '取位成功', name: '2'},
        {label: '取位失败', name: '3'},
      ],
      activeName: '0',//tab
      orderList: [],//订单列表
      productTypeList: []
    }
  },
  components: {
    Pagination,
    RefundChangeLabel
  },
  async mounted() {
    this.init();
  },
  activated() {

  },
  methods: {
    //初始化
    init() {
      this.getAllProductType()

      //获取列表数据
      this.getList();
    },

    //获取列表数据
    async getList() {
      this.loading = true;
      let res = await buyer_dom_manage_orderCancelList(this.params);
      this.orderList = res.pageResult ? res.pageResult.pageData : [];
      this.params.total =  res.pageResult ? res.pageResult.totalCount : 0;
      this.params.pageSize = res.pageResult ? res.pageResult.pageSize : 10;
      this.params.currentPage = res.pageResult ? res.pageResult.currentPage : 1;
      this.loading = false;
    },

    //选择更新日期
    changeAppTimeDate(val) {
      if (val === '' || val === null) {
        this.params.appTimeBegin = ''
        this.params.appTimeEnd = ''
      } else {
        this.params.appTimeBegin = val[0] + ' 00:00:00'
        this.params.appTimeEnd = val[1] + ' 23:59:59'
      }
    },

    //搜索
    onSearch() {
      this.getList();
    },

    //重置筛选条件
    onReset() {
      this.params = {
        appTimeBegin: '',//申请日期开始
        appTimeEnd: '',//申请日期结束
        orderNo: '',//订单号
        pnr: '',//pnr
        ticketNumber: '',//票号
        psgName: '',//乘客姓名
        canStatus: '0',//订单状态 0:全部 1：待处理 2：取位成功 3：取位失败
        total: 0,//总数
        pageSize: 10,//每页记录条数
        currentPage: 1,//当前页码
      }
      this.appTimeDate = [];//申请日期
      this.onSearch()
    },

    //切换tab
    changeNotifyStatus(tab) {
      this.activeName = tab.name;
      this.params.canStatus = Number(this.activeName);
      this.getList();
    },

    setTagType(val) {
      let type = ''
      const typeList = ['', 'success', 'info', 'warning', 'danger']
      this.productTypeList.forEach((value, index) => {
        const codeList = value.code.split(',')
        if (codeList.indexOf(val) >= 0) {
          type = typeList[index]
        }
      })
      return type
    },

    async getAllProductType() {
      let [err, res] = await awaitWrap(plat_dom_getAllProductType())
      if (err) {
        return
      }
      let productTypeList = res.producyTypes
      this.productTypeList = productTypeList
    },

    //查看详情
    toDetail(orderNo, canOrderNo) {
      this.$router.push({
        name: 'air-ticket-admin-cancel-seat-detail',
        query: {
          orderNo: orderNo,
          canOrderNo: canOrderNo
        }
      });
    }
  }
}
